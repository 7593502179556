import React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo.js"

export default ({ pageContext: {title, relatedNodes}, data }) => {

  return (
    <>
      <Layout>
        <SEO title={title} />
        <div className="post sm:mx-4 md:mx-12 lg:mx-40 mt-4">
          <div className="mx-4 sm:mx-12">
            <h1 className="text-4xl">{title}</h1>
            <hr />
            <h2 className="text-xl mt-2">Related Links</h2>
            {relatedNodes.map(node => {
              return (
                <Link className="block" to={`/${node.title}`}>{node.title}</Link>
              )
            })}
          </div>
        </div>
      </Layout>
    </>
  )
}
